import { useRef, useState } from 'react'

import { map, useSpring } from '@unlikelystudio/react-hooks'

import useElevator from '~/hooks/useElevator'

export interface ParallaxParams {
  friction?: number
  displacement?: number
  unzoom?: number
  inversed?: boolean
}

export function useGetParallaxStyleFromMaxTranslateAndHeight({
  y,
  maxTranslateY,
  height,
}) {
  const scale =
    height === 0 || isNaN(height) ? 1 : (height + maxTranslateY) / height

  return {
    transform: `translate3d(0, ${y}px, 0) scale3d(${scale}, ${scale}, ${scale})`,
    willChange: 'transform',
  }
}

export default function useParallax({
  friction = 0.3,
  displacement = 50,
}: ParallaxParams) {
  const [scrollYProgress, setScrollYProgress] = useState(0)
  const ref = useRef(null)

  const [setProgressSpring] = useSpring({
    config: { interpolation: 'basic', friction: 5, perfectStop: true },
    progress: 0,
    onUpdate: ({ progress }, { progress: prevProgress }) => {
      if (progress !== prevProgress) {
        setScrollYProgress(map(progress, 0, 1, -displacement, displacement))
      }
    },
  })

  useElevator({
    ref,
    friction,
    onProgress: (p) => {
      setProgressSpring({ progress: p })
    },
  })

  return { y: scrollYProgress, ref } as const
}
