export interface DestructSeconds {
  d: number
  h: number
  m: number
  s: number
}

export default function destructSeconds(s: number): DestructSeconds {
  let h: number, m: number

  m = Math.floor(s / 60)
  s = s % 60
  h = Math.floor(m / 60)
  m = m % 60
  const d: number = Math.floor(h / 24)
  h = h % 24

  return { d: d, h: h, m: m, s: Math.floor(s) }
}
