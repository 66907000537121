import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useTracker } from '~/providers/TrackerProvider'

export default function useTrackingPromotionImpression() {
  const { ref, inView } = useInView({ triggerOnce: true })
  const { name, isPromotion, promotionPosition } = useSliceProvider()
  const { tracker } = useTracker()

  useEffect(() => {
    if (inView && isPromotion) {
      tracker.emit(TRACKING_EVENTS.PROMOTION_IMPRESSION, {
        ecommerce: {
          promoView: {
            promotions: [
              {
                name: name?.toLocaleLowerCase() ?? 'none',
                position: `${promotionPosition}` ?? 'none',
                id: `${name?.toLocaleLowerCase()}_${promotionPosition}`,
                creative: 'none',
              },
            ],
          },
        },
      })
    }
  }, [inView])

  return { ref, inView }
}
