import classnames from 'classnames/bind'
import React from 'react'

import { Spinner } from '@unlikelystudio/react-abstract-components'

import { PauseIcon, PlayIcon } from '~/components/Abstracts/Icons'
import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PlayPauseButtonProps
  extends Pick<
    VideoState,
    'videoHandlePausePlay' | 'videoPaused' | 'videoIsLoading'
  > {
  className?: string
  color?: string
  playIcon?: React.FC<React.SVGProps<SVGSVGElement>>
  pauseIcon?: React.FC<React.SVGProps<SVGSVGElement>>
}

function PlayPauseButton({
  className,
  videoHandlePausePlay,
  videoPaused,
  videoIsLoading,
  color,
  playIcon,
  pauseIcon,
}: PlayPauseButtonProps) {
  const PlayComponent = playIcon ?? PlayIcon
  const PauseComponent = pauseIcon ?? PauseIcon

  return (
    <div
      className={cx(css.PlayPauseButton, className)}
      onClick={videoHandlePausePlay}
    >
      {videoIsLoading ? (
        <Spinner />
      ) : videoPaused ? (
        <PlayComponent fill={color} />
      ) : (
        <PauseComponent fill={color} />
      )}
    </div>
  )
}

PlayPauseButton.defaultProps = {
  color: '#000000',
}

export default PlayPauseButton
