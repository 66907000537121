import classnames from 'classnames/bind'
import React from 'react'

import { PlayIcon } from '~/components/Abstracts/Icons'
import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'
import Image, { ImageProps, Sizes } from '~/components/UI/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface VideoPlayerMockProps
  extends Pick<VideoState, 'videoHandlePausePlay'> {
  className?: string
  image?: ImageProps
  ratio?: string
  sizesFromBreakpoints?: Sizes
  children?: JSX.Element | JSX.Element[]
  icon?: JSX.Element | JSX.Element[]
  iconClassName?: string
  hide?: boolean
  style?: Record<string, string>
  controls?: boolean
  asPlaceholder?: boolean
  priority?: boolean
}

const IMAGE_SIZES = [{ ratio: 24 / 24 }]

function VideoPlayerMock({
  className,
  image,
  ratio,
  sizesFromBreakpoints,
  children,
  hide,
  videoHandlePausePlay,
  icon,
  iconClassName,
  style,
  controls = true,
  asPlaceholder = true,
  priority,
}: VideoPlayerMockProps) {
  return (
    <div
      className={cx(css.VideoPlayerMock, className, { hide, controls })}
      onClick={videoHandlePausePlay}
      style={style}
    >
      <div>
        <Image
          layout="fill"
          objectFit="cover"
          className={css.image}
          sizesFromBreakpoints={sizesFromBreakpoints || IMAGE_SIZES}
          ratio={ratio || css.ratio}
          asPlaceholder={asPlaceholder}
          priority={priority}
          {...image}
        />
        {controls && (
          <div className={cx(css.icon, iconClassName)}>
            {icon ? icon : <PlayIcon fill="#FFFFFF" width="40" height="40" />}
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

VideoPlayerMock.defaultProps = {
  visible: true,
}

export default VideoPlayerMock
