import fit from 'math-fit'
import { DependencyList, useEffect, useMemo, useState } from 'react'
import { useMeasure } from 'react-use'

const TYPES = ['contain', 'cover'] as const

interface UseMathFitParams {
  type: (typeof TYPES)[number]
}

interface IMathFit {
  left?: number
  top?: number
  width?: number
  height?: number
  scale?: number
}

export function useMathFit({ type }: UseMathFitParams, dep: DependencyList) {
  const [parentRef, parentBounds] = useMeasure()
  const [targetRef, targetBounds] = useMeasure()

  const [processed, setProcessed] = useState<IMathFit>({})

  useEffect(() => {
    if (parentBounds && targetBounds) {
      setProcessed(fit[type](targetBounds, parentBounds))
    }
  }, [targetBounds, parentBounds, type, ...dep])

  if (!TYPES.includes(type)) {
    console.error(
      `[useMathFit] Please use one of this ${TYPES.join(' | ')} as type`,
    )
    return null
  }

  return {
    parentRef,
    targetRef,
    processed,
  }
}

export function useMathFitStyle(params: UseMathFitParams, dep: DependencyList) {
  const { parentRef, targetRef, processed: mathFit } = useMathFit(params, dep)

  return useMemo(
    () => ({
      parentRef,
      targetRef,
      processedCss: {
        transformOrigin: 'top left',
        transform: `translate3d(${mathFit.left}px, ${mathFit.top}px, 0) scale3d(${mathFit.scale}, ${mathFit.scale}, ${mathFit.scale})`,
      },
    }),
    [mathFit, parentRef, targetRef],
  )
}
