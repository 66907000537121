import React, { BaseSyntheticEvent, useCallback, useState } from 'react'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

interface Position {
  x?: number
  y?: number
}

export function getRelativeCoordinates(
  pos: Position,
  el: BaseSyntheticEvent['currentTarget'],
) {
  const position = {
    x: pos?.x ?? 0,
    y: pos?.y ?? 0,
  }

  const offset = {
    left: el.offsetLeft,
    top: el.offsetTop,
  }

  let reference = el.offsetParent

  while (reference) {
    offset.left += reference.offsetLeft
    offset.top += reference.offsetTop
    reference = reference.offsetParent
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
  }
}

function formatEvent(e: React.TouchEvent | React.MouseEvent) {
  const el = e.currentTarget
  const ev =
    'touches' in e.nativeEvent ? e?.nativeEvent?.touches[0] : e?.nativeEvent

  const pos = {
    x: ev?.clientX,
    y: ev?.clientY,
  }

  return {
    el,
    pos,
  }
}

interface UseRelativePositionsProps {
  onStart?: (position: Position) => void
  onMove?: (position: Position) => void
  onEnd?: () => void
}

export default function useRelativePositions(
  props?: UseRelativePositionsProps,
) {
  const { onStart, onMove, onEnd } = props || {}

  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  })

  const [canDrag, setCanDrag] = useState(false)

  function start(e: React.TouchEvent | React.MouseEvent) {
    if (!e) return
    setCanDrag(true)
    const { pos, el } = formatEvent(e)
    const coordinates = getRelativeCoordinates(pos, el)
    setPosition(coordinates)
    onStart?.(coordinates)
  }

  function move(e: React.TouchEvent | React.MouseEvent) {
    if (!e) return
    if (!canDrag) return
    const { pos, el } = formatEvent(e)
    const coordinates = getRelativeCoordinates(pos, el)
    setPosition(coordinates)
    onMove?.(coordinates)
  }

  const end = useCallback(() => {
    setCanDrag(false)
    onEnd?.()
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (canDrag) {
      window.addEventListener('touchend', end)
      window.addEventListener('mouseup', end)
    } else {
      window.removeEventListener('touchend', end)
      window.removeEventListener('mouseup', end)
    }
  }, [canDrag])

  return {
    position,
    isDragging: canDrag,
    bindEvents: {
      onMouseDown: start,
      onMouseMove: move,
      onTouchStart: start,
      onTouchMove: move,
    },
  }
}
