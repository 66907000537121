import { useState } from 'react'

import PrismicRichText from '~/components/Abstracts/Prismic'
import { RichTextBlocks } from '~/components/Abstracts/RichText'

import truncateString from '~/utils/truncate-string'

export default function useTruncatedString(
  text: RichTextBlocks | string,
  MAX_CHARACTERS?: number,
) {
  const [expanded, setExpanded] = useState(false)

  const maxChars = MAX_CHARACTERS ?? 300
  const processedText =
    typeof text === 'string' ? text : PrismicRichText.asText(text)
  const isTextRichText = !(typeof text === 'string')
  const truncatedText = truncateString(processedText, maxChars)
  const needTruncate = processedText?.length > maxChars

  const textToDisplay = needTruncate
    ? !expanded
      ? truncatedText
      : isTextRichText
      ? text
      : processedText
    : isTextRichText
    ? text
    : processedText

  return {
    needTruncate,
    expanded,
    setExpanded,
    text: textToDisplay,
  }
}
