import classnames from 'classnames/bind'
import React from 'react'

import { MutedIcon, SoundIcon } from '~/components/Abstracts/Icons'
import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface MuteUnmuteButtonProps
  extends Pick<VideoState, 'videoHandleMuteUnmute' | 'videoMuted'> {
  className?: string
  color?: string
  mutedIcon?: React.FC<React.SVGProps<SVGSVGElement>>
  soundIcon?: React.FC<React.SVGProps<SVGSVGElement>>
}

function MuteUnmuteButton({
  className,
  videoHandleMuteUnmute,
  videoMuted,
  color,
  mutedIcon,
  soundIcon,
}: MuteUnmuteButtonProps) {
  const MutedComponent = mutedIcon ?? MutedIcon
  const SoundComponent = soundIcon ?? SoundIcon

  return (
    <div
      className={cx(css.MuteUnmuteButton, className)}
      onClick={videoHandleMuteUnmute}
    >
      {videoMuted ? (
        <MutedComponent fill={color} />
      ) : (
        <SoundComponent fill={color} />
      )}
    </div>
  )
}

MuteUnmuteButton.defaultProps = {
  color: '#000000',
}

export default MuteUnmuteButton
