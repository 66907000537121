import classnames from 'classnames/bind'
import React from 'react'

import {
  ExitFullscreenIcon,
  FullscreenIcon,
} from '~/components/Abstracts/Icons'
import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FullScreenButtonProps
  extends Pick<VideoState, 'videoHandleFullScreen' | 'videoIsFullScreen'> {
  className?: string
  color?: string
  fullScreenIcon?: React.FC<React.SVGProps<SVGSVGElement>>
  exitFullScreenIcon?: React.FC<React.SVGProps<SVGSVGElement>>
}

function FullScreenButton({
  className,
  videoHandleFullScreen,
  videoIsFullScreen,
  color,
  fullScreenIcon,
  exitFullScreenIcon,
}: FullScreenButtonProps) {
  const FSComponent = fullScreenIcon ?? FullscreenIcon
  const ExitFSComponent = exitFullScreenIcon ?? ExitFullscreenIcon

  return (
    <div
      className={cx(css.FullScreenButton, className)}
      onClick={videoHandleFullScreen}>
      {videoIsFullScreen ? (
        <ExitFSComponent fill={color} />
      ) : (
        <FSComponent fill={color} />
      )}
    </div>
  )
}

FullScreenButton.defaultProps = {
  color: '#000000',
}

export default FullScreenButton
