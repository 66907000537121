import { useIsPresent } from 'framer-motion'

import { useElevator } from '@unlikelystudio/react-hooks'

export default function useCustomElevator(
  props: Parameters<typeof useElevator>[0],
) {
  const isPresent = useIsPresent()

  return useElevator({
    ...props,
    active: isPresent,
  })
}
