import { TRACKING_EVENTS } from '~/lib/constants'

import destructSeconds from '~/utils/destruct-seconds'

interface VideoPayload {
  videoName?: string
  progress?: number
  seconds?: number
}

// Tracing steps
const stepsTracing = [0, 0.25, 0.5, 0.75].map((step) => {
  return {
    delta: step,
    stepName: step === 0 ? 'start' : `${step * 100}%`,
    isDone: false,
  }
})

let hasTrackedUntilEnd = false
let lastAction = null

function trackVideoProgress(tracker, videoPayload: VideoPayload) {
  stepsTracing.forEach((step) => {
    if (step.isDone) {
      return
    }

    if (videoPayload.progress >= step.delta) {
      step.isDone = true

      tracker.emit(TRACKING_EVENTS.INTERACTION_TAGGED_EVENT, {
        eventName: 'videoPlayback',
        eventAction: step.stepName,
        eventCategory: 'video',
        eventLabel: videoPayload.videoName ?? 'none',
        interaction: {
          video: {
            name: videoPayload.videoName?.toLowerCase() ?? 'none',
          },
        },
      })
    }
  })
}

function trackVideoProgressEnd(tracker, videoPayload: VideoPayload) {
  if (!hasTrackedUntilEnd) {
    hasTrackedUntilEnd = true

    tracker.emit(TRACKING_EVENTS.INTERACTION_TAGGED_EVENT, {
      eventName: 'videoPlayback',
      eventAction: 'end',
      eventCategory: 'video',
      eventLabel: videoPayload.videoName ?? 'none',
      interaction: {
        video: {
          name: videoPayload.videoName?.toLowerCase() ?? 'none',
        },
      },
    })
  }
}

function trackVideoPlay(tracker, videoPayload: VideoPayload) {
  if (lastAction === 'start') {
    return
  }

  lastAction = 'start'

  tracker.emit(TRACKING_EVENTS.INTERACTION_TAGGED_EVENT, {
    eventName: 'videoPlay',
    eventAction: 'play',
    eventCategory: 'video',
    eventLabel: videoPayload.videoName ?? 'none',
    interaction: {
      video: {
        name: videoPayload.videoName?.toLowerCase() ?? 'none',
      },
    },
  })
}

function trackVideoPause(tracker, videoPayload: VideoPayload) {
  if (lastAction === 'stop') {
    return
  }

  lastAction = 'stop'

  tracker.emit(TRACKING_EVENTS.INTERACTION_TAGGED_EVENT, {
    eventName: 'videoStop',
    eventAction: 'stop',
    eventCategory: 'video',
    eventLabel: videoPayload.videoName ?? 'none',
    interaction: {
      video: {
        name: videoPayload.videoName?.toLowerCase() ?? 'none',
      },
    },
  })
}

function trackVideoSeek(tracker, videoPayload: VideoPayload) {
  // To avoid tracking the rewind after the video ended
  if (videoPayload.seconds !== 0) {
    const { m, s } = destructSeconds(videoPayload.seconds)
    const paddedSeconds = s?.toString()?.padStart(2, '0')

    tracker.emit(TRACKING_EVENTS.INTERACTION_TAGGED_EVENT, {
      eventName: 'videoInteraction',
      eventAction: `seek: ${m}.${paddedSeconds}`,
      eventCategory: 'video',
      eventLabel: videoPayload.videoName ?? 'none',
      interaction: {
        video: {
          name: videoPayload.videoName?.toLowerCase() ?? 'none',
          progress: `seek: ${m}.${paddedSeconds}`,
        },
      },
    })
  }
}

export {
  trackVideoProgress,
  trackVideoProgressEnd,
  trackVideoPlay,
  trackVideoPause,
  trackVideoSeek,
}
